import React, {useState} from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import fonts from '../../styles/fonts';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import theme from '../../styles/theme';
import {Fade} from "react-reveal";

const ArticlesListContainer = styled.div`
    width: calc(100% + 10px);
    margin: -5px;
`;

const RowStyled = styled(Row)`
  @media(min-width: ${theme.breakpoints.medium}){
    padding-bottom: 70px;
  }
`;

const ProjectImageContainer = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 225px;
    overflow: hidden;
    @media(min-width:  ${theme.breakpoints.medium}) {
        height: 310px;
        padding: 5px;
    }
`;

const ProjectImage = styled(Img)`
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: .3s;
    transform: scale(${props => props.isHovered ? '1.15' : '1'});
`;

const ImageBlock = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const ArticleTitle = styled.div`
    ${fonts.MaisonNeueLight};
    font-size: 16px;
    line-height: 20px;
    margin: 20px 15px 38px 0;
    a {
      text-decoration: none;
      color: ${theme.colors.black}
    }
    &:hover {
      a {
        text-decoration: underline;
      }
    }
    @media(min-width:  ${theme.breakpoints.medium}) {
        margin: 25px 5px 90px 5px;
        font-size: 18px;
        line-height: 21.6px;
    }
`;

const DateContainer = styled.div`
    height: 54px;
    width: 54px;
    background:  ${theme.colors.black};
    border-radius: 100px;
    color:  ${theme.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${fonts.MaisonNeueDemi};
    font-size: 14px;
    position: absolute;
    top: -10px;
    right: 15px;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    @media(min-width:  ${theme.breakpoints.medium}) {
        font-size: 15px;
        height: 57px;
        width: 57px;
        top: -15px;
        right: 20px;
    }
`;

const StyledLink = styled(Link)`
  z-index: 9999;
  height: 100%;
  width: 100%;
`;

const Overlay = styled.div`
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
`;

const ArticlesList = ({articles}) => {
    const [isHovered, setIsHovered] = useState(null);
    return (
        <ArticlesListContainer>
            <Container>
                <RowStyled>
                    {articles.map(({node}, index) => {
                        return (
                            <Col key={index} md={6} lg={4}>
                                <Fade duration={750} delay={200}>
                                    <ProjectImageContainer onMouseEnter={() => setIsHovered(index)} onMouseLeave={() => setIsHovered(null)}>
                                        <StyledLink to={`/article/${node.slug}`}>
                                            <Overlay className="cursor-dot-black"/>
                                        </StyledLink>
                                        <ImageBlock>
                                            <ProjectImage fluid={node.thumbnail.fluid} alt={node.title} isHovered={isHovered === index}/>
                                        </ImageBlock>
                                    </ProjectImageContainer>
                                    <DateContainer>
                                        {node.publishedDate}
                                    </DateContainer>
                                    <ArticleTitle>
                                        <Link to={`/article/${node.slug}`}>
                                            <span className="cursor-dot-black">{node.title}</span>
                                        </Link>
                                    </ArticleTitle>
                                </Fade>
                            </Col>
                        )
                    })}
                </RowStyled>
            </Container>

        </ArticlesListContainer>

    )
}


export default ArticlesList



