import React, { Component } from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import ArticleList from './ArticleList';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import PlusDark from '../../resources/images/profile_page/plus-dark.svg';
import ArrowLeft from '../../resources/images/home_page/arrow-left.svg';
import ArrowLeftGrey from '../../resources/images/home_page/arrow-left-grey.svg';
import ArrowRight from '../../resources/images/home_page/arrow-right.svg';
import ArrowRightGrey from '../../resources/images/home_page/arrow-right-grey.svg';
import theme from '../../styles/theme';
import {Fade} from "react-reveal";

const ArticlesContainer = styled.div`
    padding-top: 120px;
    position: relative;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-top: 240px;
    }
`;

const ArticlesDescription = styled.div`
    ${fonts.CircularMedium};
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 25px;
    color: ${theme.colors.black};
    letter-spacing: 0px;
    max-width: 750px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 32px;
        line-height: 40px;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }
`;

const RowStyled = styled(Row)`
    position: relative;
    padding-bottom: ${props => props.bottom ? '50px' : props.filter ? '0' : '30px'};
    padding-top: ${props => props.bottom && '60px'};
    margin-top: ${props => props.bottom && '20px'};
    border-top: ${props => props.bottom && `1.5px solid ${theme.colors.grey}`};
    @media(min-width: ${theme.breakpoints.medium}) {
        width: ${props => props.filter && '100%'};
        padding-top: ${props => props.bottom && '50px'};
        padding-bottom: ${props => props.bottom || props.filter ? '0' : '90px'};
    }
`;

const ColStyled = styled(Col)`
    padding-left: ${props => props.filter && '10px'};
    padding-right: ${props => props.filter && '10px'};
    box-sizing: border-box;
    flex-direction: ${props => props.filter ? 'column' : 'row'};
    align-items: ${props => props.filter && 'flex-start'};
    justify-content: ${props => props.middle ? 'center' : props.last ? 'flex-end' : 'flex-start'};
    display: ${props => props.desktop ? 'none' : 'flex'};
    @media(min-width: ${theme.breakpoints.medium}) {
        display: ${props => props.desktop && 'flex'};
        display: ${props => props.mobile && 'none'};
        padding: 0;
        align-items: ${props => props.filter ? 'flex-start' : 'flex-end'};
        justify-content: ${props => props.last ? 'flex-end' : props.middle ? 'center' : 'flex-start'};
    }
`;

const FilterButton = styled.button`
    background: transparent;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    padding: 0;
    ${fonts.MaisonNeueDemi};
    font-size: 18px;
    line-height: 27px;
    color: ${theme.colors.black};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    position: relative;
    z-index: 11112;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-left: 30px;
    }
`;

const Plus = styled.img`
    height: 12px;
    width: 12px;
    padding: 15px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: ${props => props.hover && 'rotate(360deg)'};
    transform: ${props => props.hover && 'rotate(360deg)'};
    -webkit-transform: ${props => props.open && 'rotate(270deg)'};
    transform: ${props => props.open && 'rotate(315deg)'};
`;

const FilterBlock = styled.div`
  position: relative;
`;

const FilterContainer = styled.div`
    top: -15px;
    position: absolute;
    left: 0;
    right: 0;
    background: ${theme.colors.black};
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    z-index: 1;
    opacity: ${props => props.open ? '1' : '0'};
    transition: 0.4s;
    margin-left: -25px;
    margin-right: -25px;
    width: calc(100% + 50px);

    @media(min-width: ${theme.breakpoints.medium}){
      width: 100%;
      top: -20px;
      margin-left: 0;
      margin-right: 0;
    }
`;

const Category = styled.div`
    ${fonts.MaisonNeueDemi};
    font-size: 20px;
    line-height: 33px;
    color: ${theme.colors.white};
    padding-bottom: 20px;
`;

const SubCategory = styled.div`
    ${props => props.selectedCategory ? fonts.MaisonNeueDemi : fonts.MaisonNeueLight};
    font-size: 18px;
    line-height: 33px;
    cursor: pointer;
    color: ${props => props.selectedCategory ? theme.colors.white : theme.colors.darkGrey};
    &:hover,
    &:active,
    &:focus {
        color: ${theme.colors.white};
    }
`;

const ButtonText = styled.div`
    border-bottom: 2px solid transparent;
    border-bottom: ${props => props.hover && `2px solid ${theme.colors.black}`};
    border-bottom: ${props => props.open && `2px solid ${theme.colors.black}`};
`;

const PaginationButton = styled.button`
    background: transparent;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    ${fonts.MaisonNeueLight};
    font-size: 18px;
    line-height: 33px;
    color: ${theme.colors.black};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${props => props.isActive ? theme.colors.black : '#c6c6c6'};
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
`;

const Arrow = styled.img`
    width: 40px;
    height: auto;
`;

const PaginationDirection = styled.div`
    display: none;
    @media(min-width: ${theme.breakpoints.medium}) {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const PaginationNumber = styled.button`
    font-size: 18px;
    line-height: 33px;
    color: ${theme.colors.black};
    background: transparent;
    border: none;
    outline: none;
    margin-left: 20px;
    margin-right: 20px;
    ${props => props.isActive ? fonts.MaisonNeueDemi : fonts.MaisonNeueLight};
    border-bottom: 1px solid ${props => props.isActive ? theme.colors.black : 'transparent'};
`;

const FilterContent = styled.div`
  padding: 20px 15px 20px 15px;
  width: 100%;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding: 70px;
  }
`;

const NoResults = styled.div`
  padding-left: 15px;
  font-size: 24px;
  line-height: 28px;
  ${fonts.CircularMedium};
  padding-bottom: 30px;
  @media(min-width: ${theme.breakpoints.medium}){
    padding-left: 30px;
    padding-bottom: 0;
    font-size: 32px;
    line-height: 38px;
  }
`;

const pageLength = 9;

class Projects extends Component {
    state = {
        filterOptionsDisplayed: false,
        filterButtonHovered: false,
        activeFilter: null,
        page: 1
    };

    displayFilterOptions = () => this.setState({
        filterOptionsDisplayed: !this.state.filterOptionsDisplayed
    });

    hoverOverFilter = () => this.setState({
        filterButtonHovered: !this.state.filterButtonHovered
    });

    changeFilter = (filter) => { this.setState({ activeFilter: filter }); this.displayFilterOptions() };

    filterArticles = (filter) => this.props.articles.filter(article => filter ? article.node.category.slug === filter : true);


    componentDidMount() {
        const currentUrl = new URL(window.location.href);
        let searchParams = currentUrl.searchParams;
        const pagination = searchParams.get('page');

        if (pagination) {
            this.setState({
                page: pagination
            });
        }
    }

    changePage = (e, page) => {
        e.preventDefault();

        this.setState({
            page: page
        });

        this.setPageUrlParam(page);
    };

    setPageUrlParam = (page) => {
        let queryParams = new URLSearchParams(window.location.search);
        queryParams.set("page", page);

        window.history.pushState(null, null, "?" + queryParams.toString());
    };

    render() {
        const { categories, intro} = this.props;
        const { filterOptionsDisplayed, filterButtonHovered, page, activeFilter } = this.state;
        const { displayFilterOptions, hoverOverFilter, filterArticles, changeFilter, changePage } = this;
        let cursor;
        if (typeof window !== "undefined") {
            cursor = document.getElementById('custom-cursor');
        }

        const categoriesData = [];
        let size = 1;
        for (let i = 0; i < categories.length; i += size) {
            if (i > 0) size = 2;
            categoriesData.push(categories.slice(i, i + size));
        }

        const activeArticles = filterArticles(activeFilter);

        const pageEnd = page * pageLength;
        const pageStart = pageEnd - pageLength;

        let previousPage;
        let nextPage;

        if (page > 1) {
            previousPage = page - 1;
        }

        const numberOfPages = Math.ceil(activeArticles.length / pageLength);

        if (page < numberOfPages) {
            nextPage = page + 1;
        }

        return (
            <ArticlesContainer>
                <Container>
                    <RowStyled>
                        <ColStyled md={4} desktop>
                            <Fade duration={500}>
                                <FilterButton onClick={() => displayFilterOptions()} onMouseEnter={hoverOverFilter}
                                              onMouseLeave={hoverOverFilter}>
                                    <ButtonText hover={filterButtonHovered}
                                                open={filterOptionsDisplayed}>categories </ButtonText>
                                    <Plus src={PlusDark} hover={filterButtonHovered} open={filterOptionsDisplayed} className="cursor-dot-black"/>
                                </FilterButton>
                            </Fade>
                        </ColStyled>
                        <ColStyled md={8}>
                            <Fade duration={750} delay={100}>
                                <ArticlesDescription>{intro}</ArticlesDescription>
                            </Fade>
                        </ColStyled>
                        <ColStyled mobile>
                            <FilterButton onClick={() => displayFilterOptions()}>
                                <ButtonText hover={filterButtonHovered}
                                            open={filterOptionsDisplayed}>categories </ButtonText>
                                <Plus src={PlusDark} hover={filterButtonHovered} open={filterOptionsDisplayed}/>
                            </FilterButton>
                        </ColStyled>
                    </RowStyled>
                </Container>
                <FilterBlock>
                    <FilterContainer open={filterOptionsDisplayed} className="cursor-white">
                        {filterOptionsDisplayed &&
                        <FilterContent>
                            <RowStyled filter={'true'}>
                                <ColStyled xs={12} sm={6} md={6} filter={'true'}>
                                    <Container>
                                        <RowStyled filter={'true'}>
                                            <Col md={12}>
                                                <Category>Specialism</Category>
                                            </Col>
                                            {categoriesData.map((category, index) =>
                                              <ColStyled filter={'true'} md={6} key={index}>
                                                  {index === 0 &&
                                                  <SubCategory onClick={() => changeFilter(null)} selectedCategory={activeFilter === null} onMouseEnter={() => cursor?.classList?.add("dot-white")} onMouseLeave={() => cursor?.classList?.remove("dot-white")}>All Categories</SubCategory>}
                                                  {category.map((categoryData, index) =>
                                                    <SubCategory key={index} onClick={() => changeFilter(categoryData.node.slug)} selectedCategory={activeFilter === categoryData.node.slug}>
                                                        <span onMouseEnter={() => cursor?.classList?.add("dot-white")} onMouseLeave={() => cursor?.classList?.remove("dot-white")}>{categoryData.node.name}</span>
                                                    </SubCategory>
                                                  )}
                                              </ColStyled>
                                            )}
                                        </RowStyled>
                                    </Container>
                                </ColStyled>
                            </RowStyled>
                        </FilterContent>}
                    </FilterContainer>
                </FilterBlock>
                <ArticleList articles={activeArticles.slice(pageStart, pageStart+pageLength)} />
                {activeArticles.length > pageLength &&
                <Container>
                    <RowStyled bottom>
                        <ColStyled xs={2} sm={2} md={4} first>
                            <PaginationButton isActive={previousPage} onClick={(e) => previousPage && changePage(e, previousPage)}>
                                <Arrow src={previousPage ? ArrowLeft : ArrowLeftGrey} alt={"Previous Page"}/>
                                <PaginationDirection>prev</PaginationDirection>
                            </PaginationButton>
                        </ColStyled>
                        <ColStyled xs={8} sm={8} md={4} middle>
                            {[...Array(numberOfPages).keys()].map(index => {
                                return <PaginationNumber key={index} onClick={(e) => changePage(e,index+1)} isActive={page===(index+1)}>{index+1}</PaginationNumber>
                            })}
                        </ColStyled>
                        <ColStyled xs={2} sm={2} md={4} last>
                            <PaginationButton isActive={nextPage} onClick={(e) => nextPage && changePage(e, nextPage)}>
                                <PaginationDirection>next</PaginationDirection>
                                <Arrow src={nextPage ? ArrowRight : ArrowRightGrey} alt={"Next Page"}/>
                            </PaginationButton>
                        </ColStyled>
                    </RowStyled>
                </Container>}
                {activeArticles.length < 1 &&
                    <Container>
                        <Row>
                            <Col>
                                <NoResults>No results found</NoResults>
                            </Col>
                        </Row>
                    </Container>
                }
            </ArticlesContainer>
        )
    }
}

export default Projects;
