import React, {Component} from 'react';
import styled from 'styled-components';
import SEO from '../components/Seo';
import get from 'lodash/get'
import Layout from "../components/Layout";
import Articles from '../components/articles/Articles';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import theme from '../styles/theme';

const LayoutStyled = styled(Layout)`
  margin: 0;
`;

const WhiteBackground = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 25px;
  @media(min-width: ${theme.breakpoints.large}) {
    padding-left: 140px;
    padding-right: 140px;
  }
`;

class ProjectsPage extends Component {
    render() {
        const path = this.props.location.pathname;
        const articles = get(this, 'props.data.allContentfulArticle.edges');
        const categories = get(this, 'props.data.allContentfulArticleCategory.edges');
        const articlesPage = get(this, 'props.data.allContentfulArticlePage.edges')[0].node;

        return (
            <LayoutStyled colorScheme="dark">
                <SEO title="Lately | Society Studio"
                     description="" fullTitle={true}
                     path={path}/>
                <WhiteBackground>
                    <Articles
                        articles={articles}
                        categories={categories}
                        intro={documentToReactComponents(articlesPage.intro.json)}/>
                </WhiteBackground>
            </LayoutStyled>
        )
    }
};

export default ProjectsPage;

export const pageQuery = graphql`
    query LatelyQuery {
        allContentfulArticle(sort: { fields: publishedDate, order: DESC }) {
            edges {
                node {
                    id
                    title
                    slug
                    category {
                        id
                        name
                        slug
                    }
                    intro {
                        json
                    }
                    publishedDate(formatString:"MMM DD")
                    thumbnail: thumbnail {
                        fluid(
                            maxWidth: 1000
                            quality: 60
                        ) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }
        allContentfulArticleCategory {
            edges {
                node {
                    slug
                    name
                }
            }
        }
        allContentfulArticlePage {
            edges {
                node {
                    intro {
                        json
                    }
                }
            }
        }
    }
`;
